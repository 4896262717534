import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { buscarMisMedicos, eliminarMedico } from '../../actions/medicos';

const Medicos = ({ history }) => {

    const dispatch = useDispatch();
    const medicos = useSelector(state => state.medicos);

    const tableRow = (items) => {

        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.apellido}</td>
                            <td>{item.telefono}</td>
                            <td>
                                <Link className="btn btn-link text-success" to={`/medicos/detalles/${item.id_medico}`}>Detalles</Link>
                            </td>
                            <td>
                                <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarMedico(id));
    }

    useEffect(() => {
        dispatch(buscarMisMedicos());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Mis médicos</h3>
                        <p className="text-muted">{medicos.mis_medicos?.length} médico{medicos.mis_medicos?.length == 1 ? '' : 's'} gestionado{medicos.mis_medicos?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        <Link to="/medicos/agregar" className="btn btn-success text-right">Agregar médico</Link>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>
                            <th scope="col">Teléfono</th>                            
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(medicos.mis_medicos !== undefined ? medicos.mis_medicos : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Medicos;