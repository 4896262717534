import { LOAD_COBROS, DETAIL_COBRO, LOAD_FACTURA } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

export const buscarCobros = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/distri_pagos/`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_COBROS,
            payload: res.data.pagos
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const detallesCobro = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/distri_pagos/detalles`, body, config);
        dispatch(changeLoader(false));
        dispatch({
            type: DETAIL_COBRO,
            payload: res.data.pago
        });
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const actualizarFactura = (factura) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/distri_pagos/facturar`, factura, config);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_FACTURA,
            payload: res.data.pago
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch(changeLoader(false));
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}