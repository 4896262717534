import { LOAD_COBROS, DETAIL_COBRO, LOAD_FACTURA } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_COBROS:
        	return {
                ...state,
                cobros: payload
            }
        case DETAIL_COBRO:
        	return {
                ...state,
                detalles: payload
            }
        case LOAD_FACTURA:
            return {
                ...state,
                detalles: payload
            }
        default:
            return state;
    }
}