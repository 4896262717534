import { LOAD_MEDICOS, LOAD_MIS_MEDICOS, DETAIL_MEDICO, DELETE_MEDICO, LOAD_SOLICITUDES, ACCEPT_MEDICO } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_MEDICOS:
        	return {
                ...state,
                medicos: payload
            }
        case LOAD_MIS_MEDICOS:
            return {
                ...state,
                mis_medicos: payload
            }
        case DETAIL_MEDICO:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_MEDICO:
            return {
                ...state,
                mis_medicos: state.mis_medicos.filter( medico => medico._id !== payload.id )
            }
        case LOAD_SOLICITUDES:
            return {
                ...state,
                solicitudes: payload
            }
        case ACCEPT_MEDICO:
            return {
                ...state,
                solicitudes: state.solicitudes.filter( solicitud => solicitud._id !== payload.id )
            }
        default:
            return state;
    }
}