import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { buscarCobros } from '../../actions/cobros';

const Cobros = ({ history }) => {

    const dispatch = useDispatch();
    const cobros = useSelector(state => state.cobros);

    const tableRow = (items) => {

        const numberToDecimal = (number) => {
            number = (Math.round(number * 100) / 100).toFixed(2);
            const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
            const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return finalFormated;
        }

        const facturaReminder = (pagado) => {
            if(pagado){
                return <Badge variant="success">Realizado</Badge>;
            }else{
                return <Badge variant="warning">Pendiente</Badge>;
            }
        }

        const pagoReminder = (pagado) => {
            if(pagado){
                return <Badge variant="success">Realizado</Badge>;
            }else{
                return <Badge variant="warning">Pendiente</Badge>;
            }
        }

        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{numberToDecimal(item.total)}</td>
                            <td>{numberToDecimal(item.total_factura)}</td>
                            <td>{new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>{facturaReminder(item.facturado)}</td>
                            <td>{pagoReminder(item.pagado)}</td>
                            <td>
                                <Link className="btn btn-link text-success" to={`/cobros/detalles/${item._id}`}>Detalles</Link>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    useEffect(() => {
        dispatch(buscarCobros());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-12">
                        <h3>Cobros de comisiones</h3>
                        <p className="text-muted">{cobros.cobros?.length} cobro{cobros.cobros?.length == 1 ? '' : 's'} registrado{cobros.cobros?.length == 1 ? '' : 's'}</p>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Total</th>
                            <th scope="col">Total factura</th>
                            <th scope="col">Fecha</th>                        
                            <th scope="col">Facturado</th>                            
                            <th scope="col">Pagado</th>                            
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(cobros.cobros !== undefined ? cobros.cobros : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Cobros;