import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { buscarVentasDirectas } from '../../actions/ventas';

const VentasDirectas = ({ history }) => {

    const dispatch = useDispatch();
    const ventas = useSelector(state => state.ventas);

    const tableRow = (items) => {

        const ordenLinker = (id_orden, folio_orden) => {
            if(id_orden != null){
                return <Link className="btn btn-link text-primary" to={`/ordenes/detalles/${id_orden}`}>{folio_orden}</Link>
            }else{
                return <Badge variant="danger">Sin órden asignada</Badge>;
            }
        }

        const statusReminder = (status) => {
            switch(status){
                case 0:
                    return <Badge variant="primary">Pendiente</Badge>;
                    break;

                case 1:
                    return <Badge variant="warning">En proceso</Badge>;
                    break;

                case 2:
                    return <Badge variant="success">Entregada</Badge>;
                    break;

                case 3:
                    return <Badge variant="danger">Cancelada</Badge>;
                    break;
            }
        }

        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre_medico}</td>
                            <td>{item.cantidad}</td>
                            <td>{new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>{ordenLinker(item.id_orden, item.folio_orden)}</td>
                            <td>{statusReminder(item.status)}</td>
                            <td>
                                <Link className="btn btn-link text-success" to={`/ventas/detalles/${item._id}`}>Detalles</Link>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    useEffect(() => {
        dispatch(buscarVentasDirectas());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-12">
                        <h3>Ventas directas a médicos</h3>
                        <p className="text-muted">{ventas.ventas?.length} venta{ventas.ventas?.length == 1 ? '' : 's'} registrada{ventas.ventas?.length == 1 ? '' : 's'}</p>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Médico</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Fecha</th>                        
                            <th scope="col">Órden</th>                        
                            <th scope="col">Status</th>                            
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(ventas.ventas !== undefined ? ventas.ventas : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default VentasDirectas;