import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { buscarSolicitudes, aceptarMedico, rechazarMedico } from '../../actions/medicos';

const SolicitudesMedicos = ({ history }) => {

    const dispatch = useDispatch();
    const medicos = useSelector(state => state.medicos);

    const tableRow = (items) => {

        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.apellido}</td>
                            <td>{item.telefono}</td>
                            <td>
                                <button className="btn btn-link text-success" onClick={() => aceptar(item._id)}>Aceptar</button>
                            </td>
                            <td>
                                <button className="btn btn-link text-danger" onClick={() => rechazar(item._id)}>Rechazar</button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const aceptar = (id) => {
        dispatch(aceptarMedico(id));
    }

    const rechazar = (id) => {
        dispatch(rechazarMedico(id));
    }

    useEffect(() => {
        dispatch(buscarSolicitudes());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Solicitudes de amistad</h3>
                        <p className="text-muted">{medicos.solicitudes?.length} solicitud{medicos.solicitudes?.length == 1 ? '' : 'es'} pendiente{medicos.solicitudes?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        <Link to="/medicos/agregar" className="btn btn-success text-right">Agregar médico</Link>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>
                            <th scope="col">Teléfono</th>                            
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(medicos.solicitudes !== undefined ? medicos.solicitudes : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default SolicitudesMedicos;