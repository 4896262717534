import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { setAlert } from '../../actions/alert';

import { editarOrden, buscarEditables, detallesOrden } from '../../actions/ordenes';

registerLocale('es', es);

const styles = {
    check_zone: {
        display: 'flex',
        alignItems: 'center'
    }
}

const OrdenEditar = ({ history }) =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const orden = useSelector(state => state.ordenes);

    const tableRow = (items) => {

        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td style={styles.check_zone}>
                                <div className="form-check">
                                    <input id={item._id} type="checkbox" onChange={e => handleCheckbox(e)} value={item.cantidad} disabled={item.status == 0 ? false : true} checked={ordenData.compras_id?.includes(item._id) == true ? true : false}></input>
                                </div>
                            </td>
                            <td>{item.nombre_medico}</td>
                            <td>{item.cantidad}</td>
                            <td>{new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(cantidadData.cantidad > 0){
                dispatch(editarOrden(ordenData));
                history.push('/ordenes');
            }else{
                dispatch(setAlert('La cantidad debe ser mayor a 0', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleCheckbox = ({ target }) => {
        let cantidad_sumar = target.value;

        if(target.checked){
            setOrdenData({
                ...ordenData,
                compras_id: [...ordenData.compras_id, target.id]
            });

            setCantidadData({
                ...cantidadData, 
                cantidad: parseInt(cantidadData.cantidad) + parseInt(cantidad_sumar)
            });
        }else{
            setOrdenData({
                ...ordenData,
                compras_id: ordenData.compras_id.filter( compra_id => compra_id !== target.id )
            });

            setCantidadData({
                ...cantidadData, 
                cantidad: parseInt(cantidadData.cantidad) - parseInt(cantidad_sumar)
            });
        }
    }
    
    const [ ordenData, setOrdenData ] = useState({
        id: '',
        observaciones: '',
        compras_id: []
    });

    const [ cantidadData, setCantidadData ] = useState({
        cantidad: 0
    });

    useEffect(() => {
        dispatch(detallesOrden(id));

        dispatch(buscarEditables(id));
    }, [id]);

    useEffect(() => {
        setOrdenData({
            ...ordenData,
            id: orden.detalles?._id,
            compras_id: orden.detalles?.compras_id,
            observaciones: orden.detalles?.observaciones
        });

        setCantidadData({
            ...cantidadData,
            cantidad: orden.detalles?.cantidad
        });
    }, [orden]);
    
    const onChange = (e) => {
        setOrdenData({
            ...ordenData, [e.target.name]: e.target.value
        })
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Editar órden</h3>
                <div className="row">
                    <div className="col-md-8 form-group">
                        <label className="font-weight-bold">Observaciones o comentarios:</label> <br></br>
                        <textarea className="form-control" placeholder="Observaciones o comentarios" name="observaciones" rows="3" onChange={e => onChange(e)} value={ordenData.observaciones}></textarea>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Cantidad:</label> <br></br>
                        <input type="number" className="form-control" placeholder="Cantidad" name="cantidad" min="0" value={cantidadData.cantidad} disabled></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Seleccionar</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Médico</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            {
                                tableRow(orden.editables !== undefined ? orden.editables : [])
                            }
                        </table>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit}>Editar órden</button>
            </form>
        </div>
    )
}

export default OrdenEditar;