import React, { useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { detallesMedico, eliminarMedico } from '../../actions/medicos';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const MedicoDetalles = () => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>No hay empleados asignados aún</b></p>);

    const { id } = useParams();

    const dispatch = useDispatch();

    const medico = useSelector(state => state.medicos);

    useEffect(() => {
        dispatch(detallesMedico(id));
    }, [id]);

    const eliminar = (id) => {
        dispatch(eliminarMedico(id));
    }

    const columnsEmpleados = [
        {
            name: 'Nombre',
            selector: 'nombre',
            sortable: true,
            cell: item => item.nombre ? item.nombre : '-',
        },
        {
            name: 'Teléfono',
            selector: 'telefono',
            sortable: true,
            cell: item => item.telefono ? item.telefono : '-',
        },
        {
            name: 'Fecha de ingreso',
            selector: 'date',
            sortable: false,
            cell: item => new Date(item.date).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: '',
            selector: 'id',
            sortable: false,
            cell: item => <Link className="btn btn-link text-primary" to={`/cajeros/editar/${item._id}/${id}`}>Editar</Link>,
        },
        {
            name: '',
            selector: 'id',
            sortable: false,
            cell: item => <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>,
        }
    ]

    return (
        medico.detalles ? <Fragment>
            <div className="row px-5 pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Indicadores generales</h5>
                            <div className="row">
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{medico.detalles.num_compras}</p>
                                    <p style={styles.infoText}>compras realizadas</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{medico.detalles.num_unidades}</p>
                                    <p style={styles.infoText}>unidades compradas</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(medico.detalles.ganancias_directas)
                                    }</p>
                                    <p style={styles.infoText}>compras directas</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(medico.detalles.ganancias_indirectas)
                                    }</p>
                                    <p style={styles.infoText}>compras indirectas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Información del médico</h5>
                            <div className="row">
                                <div className="col-md-3 px-5">
                                    <div className="d-flex justify-content-center">
                                        { medico.detalles.url_perfil ?
                                            <img
                                                height="150"
                                                className="my-3 text-center"
                                                src={medico.detalles.url_perfil}
                                            ></img>
                                        :   [
                                                (medico.detalles.sexo == 'm' ?
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_dr.png"
                                                        ></img>
                                                    :
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_dra.png"
                                                        ></img>
                                                )
                                            ]
                                        }
                                    </div>
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 text-center">
                                            <h3 className="text-muted"><Badge variant="warning">Médico</Badge></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 row">
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Nombre</p>
                                        <p style={styles.info}>{medico.detalles.nombre} {medico.detalles.apellido}</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p style={styles.subtitulo}>Dirección</p>
                                        <p style={styles.info}>{medico.detalles.consultorio?.direccion}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Teléfono</p>
                                        <p style={styles.info}>{medico.detalles.telefono}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Correo</p>
                                        <p style={styles.info}>{medico.detalles.email}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default MedicoDetalles;
