import { combineReducers } from 'redux';
import alert from './alert'
import auth from './auth'
import dashboard from './dashboard'
import ventas from './ventas'
import ordenes from './ordenes'
import cobros from './cobros'
import medicos from './medicos'
import loader from './loader'

export default combineReducers({
    alert, auth, dashboard, ventas, ordenes, cobros, medicos, loader
})