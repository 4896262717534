import { LOAD_VENTAS, LOAD_VENTAS_INDIRECTAS, DETAIL_VENTA, LOAD_STATUS } from '../actions/types'

const initialState = {
    ventas: [],
    ventas_indirectas: [],
    detalles: {}
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_VENTAS:
        	return {
                ...state,
                ventas: payload
            }
        case LOAD_VENTAS_INDIRECTAS:
            return {
                ...state,
                ventas_indirectas: payload
            }
        case DETAIL_VENTA:
        	return {
                ...state,
                detalles: payload
            }
        case LOAD_STATUS:
            return {
                ...state,
                detalles: payload
            }
        default:
            return state;
    }
}