import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { buscarMedicos, agregarMedico } from '../../actions/medicos';

const AgregarMedico = ({ history }) => {

    const dispatch = useDispatch();
    const medicos = useSelector(state => state.medicos);

    const [busqueda, setBusqueda] = useState('');
    const [medicosFiltrados, setMedicosFiltrados] = useState([]);

    const tableRow = (items) => {

        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.apellido}</td>
                            <td>
                                <button className="btn btn-link text-primary" onClick={() => invitar(item._id)}>Mandar solicitud</button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const invitar = (id) => {
        dispatch(agregarMedico(id));
    }

    const onChangeBusqueda = (text) => {
        setBusqueda(text);
        text = text.toLowerCase();

        if(text.trim() != ''){        
            setMedicosFiltrados(medicos.medicos?.filter(medico => {
                const value = medico['nombre_completo'].toLowerCase();
                if (value.includes(text)) {
                    return true;
                } else {
                    return false;
                }
            }));
        }else{
            setMedicosFiltrados([]);
        }
    }

    useEffect(() => {
        dispatch(buscarMedicos(busqueda));
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-5 mt-5">
                    <div className="col-md-12">
                        <h3>Agregar nuevo médico</h3>
                        <p className="text-muted">{medicosFiltrados.length} médico{medicosFiltrados.length == 1 ? '' : 's'} encontrado{medicosFiltrados.length == 1 ? '' : 's'}</p>
                    </div>
                    <div class="col-md-12 input-group">
                        <input type="text" class="form-control" placeholder="Médico a agregar" value={busqueda} onChange={e => onChangeBusqueda(e.target.value)} aria-label="Médico a agregar" aria-describedby="basic-addon1" />
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Escribe para buscar</span>
                        </div>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>                           
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(medicosFiltrados)
                    }
                </table>
            </div>
        </div>
    )
}


export default AgregarMedico;