import { LOAD_ORDENES, LOAD_ELEGIBLES, LOAD_EDITABLES, ADD_ORDEN, DETAIL_ORDEN, EDIT_ORDEN, DELETE_ORDEN, LOAD_ACCION } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LOAD_ORDENES:
        	return {
                ...state,
                ordenes: payload
            }
        case LOAD_ELEGIBLES:
            return {
                ...state,
                elegibles: payload
            }
        case LOAD_EDITABLES:
            return {
                ...state,
                editables: payload
            }
        case ADD_ORDEN:
            return {
                ...state,
                ordenes: [
                    payload, ...state.ordenes
                ]
            }
        case EDIT_ORDEN:
            return {
                ...state,
                ordenes: state.ordenes.map( orden => orden._id === payload._id ? payload : orden)
            }
        case DETAIL_ORDEN:
        	return {
                ...state,
                detalles: payload
            }
        case DELETE_ORDEN:
            return {
                ...state,
                ordenes: state.ordenes.map( orden => orden._id === payload._id ? payload : orden)
            }
        case LOAD_ACCION:
            return {
                ...state,
                detalles: payload
            }
        default:
            return state;
    }
}