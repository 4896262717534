import React, { useEffect, useState, createRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { setAlert } from '../../actions/alert';

import { guardarPerfil } from '../../actions/configuracion';

registerLocale('es', es);

const Configuracion = ({ history }) =>{

    const dispatch = useDispatch();

    const perfil = useSelector(state => state.auth);
    
    const [perfilData, setPerfilData ] = useState({
        nombre: '',
        telefono: '',
        sexo: '',
        email: '',
        password: '',
        titular: '',
        cuenta: '',
        clabe: '',
        banco: '',
        imagen: '',
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined,
    });

    let img = createRef();

    useEffect(() => {
        setPerfilData({
            ...perfilData,
            id: perfil.distribuidor?._id,
            nombre: perfil.distribuidor?.nombre,
            telefono: perfil.distribuidor?.telefono,
            sexo: perfil.distribuidor?.sexo,
            email: perfil.distribuidor?.email,
            titular: perfil.distribuidor?.datos_bancarios?.titular || '',
            cuenta: perfil.distribuidor?.datos_bancarios?.cuenta || '',
            clabe: perfil.distribuidor?.datos_bancarios?.clabe || '',
            banco: perfil.distribuidor?.datos_bancarios?.banco || '',
            imagen: perfil.distribuidor?.url_perfil_g
        })
    }, [perfil]);

    const onChange = (e) => {
        setPerfilData({
            ...perfilData, [e.target.name]: e.target.value
        })
    }

    const onChangeImg = () => {
        setProfilePicture({
            ...profilePicture, img: img.current.files[0]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        console.log(perfilData)
        try {
            // eslint-disable-next-line
            formData.append('nombre', perfilData.nombre),
            formData.append('telefono', perfilData.telefono),
            formData.append('sexo', perfilData.sexo),
            formData.append('email', perfilData.email),
            formData.append('password', perfilData.password),
            formData.append('titular', perfilData.titular),
            formData.append('cuenta', perfilData.cuenta),
            formData.append('clabe', perfilData.clabe),
            formData.append('banco', perfilData.banco),
            formData.append('perfil', profilePicture.img);
            console.log(formData)
            dispatch(guardarPerfil(formData));
        } catch (error) {
            return false;
        }
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Información personal</h3>
                <div className="row mb-3">
                    <div className="col-12 form-group">
                        <div className="picture-container">
                            <div className="picture">
                                <img src={profilePicture.img != null ? URL.createObjectURL(profilePicture.img) : perfilData.imagen} className="picture-src" id="wizardPicturePreview" title=""/>
                                <input type="file" name="image" id="image" ref={img} onChange={onChangeImg} accept="image/*"/>
                            </div>
                            <p className="icon bg-purple">+</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Nombre:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e)} value={perfilData.nombre}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Teléfono:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Télefono de contacto" name="telefono" onChange={e => onChange(e)} value={perfilData.telefono}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Sexo</label> <br></br>
                        <select className="form-control" name="sexo" value={perfilData.sexo || ''} onChange={e => onChange(e)}>
                            <option value="">Elegir sexo</option>
                            <option value="f">Femenino</option>
                            <option value="m">Masculino</option>
                        </select>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Email:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Email de acceso" name="email" onChange={e => onChange(e)} value={perfilData.email}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña de acceso" name="password" onChange={e => onChange(e)} value={perfilData.password}></input>
                    </div>
                </div>

                <h3 className="mb-3 mt-5">Datos bancarios</h3>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Titular de la cuenta:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Titular" name="titular" onChange={e => onChange(e)} value={perfilData.titular}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Número de cuenta:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Cuenta" name="cuenta" onChange={e => onChange(e)} value={perfilData.cuenta}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Clabe interbancaria:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Clabe" name="clabe" onChange={e => onChange(e)} value={perfilData.clabe}></input>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Banco:</label> <br></br>
                        <input type="text" className="form-control" placeholder="Banco" name="banco" onChange={e => onChange(e)} value={perfilData.banco}></input>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right mb-3" onClick={handleSubmit}>Guardar perfil</button>
            </form>
        </div>
    )
}

export default Configuracion;