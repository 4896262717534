import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Login
import Login from './components/auth/Login'

// Layout
import NotFoundPage from './components/routing/NotFoundPage'
import PrivateRoute from './components/routing/PrivateRoute'
import Layout from './components/layout/Layout';
import Alert from './components/layout/Alert'

// Dashboard
import Dashboard from './components/dashboard/Dashboard'

// Ventas
import VentasDirectas from './components/ventas/VentasDirectas'
import VentasIndirectas from './components/ventas/VentasIndirectas'
import VentaDetalles from './components/ventas/VentaDetalles'

// Órdenes
import Ordenes from './components/ordenes/Ordenes'
import OrdenNueva from './components/ordenes/OrdenNueva'
import OrdenEditar from './components/ordenes/OrdenEditar'
import OrdenDetalles from './components/ordenes/OrdenDetalles'

// Cobros
import Cobros from './components/cobros/Cobros'
import CobroDetalles from './components/cobros/CobroDetalles'

// Médicos
import Medicos from './components/medicos/Medicos'
import MedicoDetalles from './components/medicos/MedicoDetalles'
import AgregarMedico from './components/medicos/AgregarMedico'
import SolicitudesMedicos from './components/medicos/SolicitudesMedicos'

// Configuración
import Configuracion from './components/configuracion/Configuracion'

// Redux
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth'


const App = () => {

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    useEffect(() => {
        store.dispatch(loadUser());
    }, [])


    return (<Provider store={store}>
        <Router>
            <Fragment>
                <Layout></Layout>
                <section className="container-fluid" style={{ paddingLeft: '64px' }}>
                    <Alert></Alert>
                    <Switch>
                        <Route exact path="/" component={Login} />

                        {/* Dashboard */}
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />

                        {/* Ventas */}
                        <PrivateRoute exact path="/ventas/directas" component={VentasDirectas} />
                        <PrivateRoute exact path="/ventas/indirectas" component={VentasIndirectas} />
                        <PrivateRoute exact path="/ventas/detalles/:id" component={VentaDetalles} />

                        {/* Órdenes */}
                        <PrivateRoute exact path="/ordenes" component={Ordenes} />
                        <PrivateRoute exact path="/ordenes/nueva" component={OrdenNueva} />
                        <PrivateRoute exact path="/ordenes/editar/:id" component={OrdenEditar} />
                        <PrivateRoute exact path="/ordenes/detalles/:id" component={OrdenDetalles} />

                        {/* Cobros */}
                        <PrivateRoute exact path="/cobros" component={Cobros} />
                        <PrivateRoute exact path="/cobros/detalles/:id" component={CobroDetalles} />

                        {/* Médicos */}
                        <PrivateRoute exact path="/medicos" component={Medicos} />
                        <PrivateRoute exact path="/medicos/agregar" component={AgregarMedico} />
                        <PrivateRoute exact path="/medicos/detalles/:id" component={MedicoDetalles} />
                        <PrivateRoute exact path="/medicos/solicitudes" component={SolicitudesMedicos} />

                        {/* Configuración */}
                        <PrivateRoute exact path="/configuracion" component={Configuracion} />

                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </section>
            </Fragment>
        </Router>
    </Provider>)
}

export default App;
