import React, { createRef, useEffect, useState, Fragment } from 'react'
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Badge, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { setAlert } from '../../actions/alert';
import { detallesCobro, actualizarFactura } from '../../actions/cobros';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    btnStatus: {
        cursor: 'pointer',
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const CobroDetalles = () => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>No hay resultados a mostrar</b></p>);

    const { id } = useParams();

    const dispatch = useDispatch();

    const cobro = useSelector(state => state.cobros);
    
    const [facturaModal, setFacturaModal] = useState(false);

    const [cobroData, setCobroData] = useState({
        id: '',
        facturado: false
    });

    const [facturaData, setFacturaData] = useState({
        pdf: undefined,
        xml: undefined
    });

    let pdf = createRef();
    
    let xml = createRef();

    const onChangePdf = () => {
        setFacturaData({
            ...facturaData, pdf: pdf.current.files[0]
        })
    }

    const onChangeXml = () => {
        setFacturaData({
            ...facturaData, xml: xml.current.files[0]
        })
    }

    const handleFactura = () => {
        setFacturaModal(true);
    }

    const changeFacturado = (id, facturado) => {

        let formData = new FormData();
        try {
            if(facturaData.pdf != undefined && facturaData.xml != undefined){
                formData.append('id', id);
                formData.append('pdf', facturaData.pdf);
                formData.append('xml', facturaData.xml);

                dispatch(actualizarFactura(formData));
                setCobroData({ ...cobroData, facturado: facturado });
                setFacturaData({
                    ...facturaData, 
                    pdf: undefined,
                    xml: undefined
                });
                setFacturaModal(false);
            }else{
                dispatch(setAlert('No puedes procesar la factura sin los archivos', 'danger'));
                setFacturaModal(false);
            }
        } catch (error) {
            dispatch(setAlert('Ocurrió un error al procesar la factura', 'danger'));
            setFacturaModal(false);
        }
    }

    useEffect(() => {
        dispatch(detallesCobro(id));
    }, [id]);

    useEffect(() => {
        setCobroData({
            ...cobroData,
            id: cobro.detalles?._id,
            facturado: cobro.detalles?.facturado
        });
    }, [cobro]);

    const statusVentas = (status) => {
        switch(status){
            case 0:
                return <Badge variant="primary">Pendiente</Badge>;
                break;

            case 1:
                return <Badge variant="warning">En proceso</Badge>;
                break;

            case 2:
                return <Badge variant="success">Entregada</Badge>;
                break;

            case 3:
                return <Badge variant="danger">Cancelada</Badge>;
                break;
        }
    }

    const columnsVentas = [
        {
            name: 'Cantidad',
            selector: 'cantidad',
            sortable: true,
            cell: item => item.cantidad ? item.cantidad : '-',
        },
        {
            name: 'Total',
            selector: 'total',
            sortable: true,
            cell: item => new Intl.NumberFormat(
                'es-MX',
                { style: 'currency', currency: 'MXN' }).format(item.total ? item.total : 0),
        },
        {
            name: 'Médico',
            selector: 'nombre_medico',
            sortable: true,
            cell: item => item.nombre_medico ? item.nombre_medico : '-',
        },
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            cell: item => statusVentas(item.status),
        }
    ]

    const columnsAcciones = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Acción',
            selector: 'descripcion',
            sortable: true,
            cell: item => item.descripcion ? item.descripcion : '-',
        }
    ]

    const facturaReminder = (facturado) => {
        if(facturado){
            return <Badge variant="success">Realizado</Badge>;
        }else{
            return <Badge variant="warning" style={styles.btnStatus} onClick={() => handleFactura()}>Pendiente</Badge>;
        }
    }

    const pagoReminder = (pagado) => {
        if(pagado){
            return <Badge variant="success">Realizado</Badge>;
        }else{
            return <Badge variant="warning">Pendiente</Badge>;
        }
    }

    const botonesSolicitarFactura = (facturado) => {
        if(facturado){
            return <Button variant="success">
                Factura generada
            </Button>;
        }else{
            return <Button variant="primary" onClick={() => changeFacturado(cobro.detalles._id, true)}>
                Procesar factura
            </Button>;
        }
    }

    return (
        cobro.detalles ? <Fragment>
            {/* Modal de status */}
            <Modal size='lg' show={facturaModal} onHide={() => {
                setFacturaModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Facturación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <p style={styles.subtitulo}>Razón Social</p>
                            <p style={styles.info}>Marieli Muñoz Pedroza</p>
                        </div>
                        <div className="col-md-6 form-group">
                            <p style={styles.subtitulo}>RFC</p>
                            <p style={styles.info}>MUPM9311128H0</p>
                        </div>
                        <div className="col-md-12 form-group">
                            <p style={styles.subtitulo}>Domicilio fiscal</p>
                            <p style={styles.info}>Tlaloc #518 Fracc. Prados del sur  C.P. 20280</p>
                        </div>
                        <div className="col-md-6 form-group">
                            <p style={styles.subtitulo}>Correo</p>
                            <p style={styles.info}>marieli_mmp@yahoo.com</p>
                        </div>
                        <div className="col-md-6 form-group">
                            <p style={styles.subtitulo}>Teléfono</p>
                            <p style={styles.info}>4494402420</p>
                        </div>
                        <div className="col-md-12 form-group">
                            <p style={styles.subtitulo}>Concepto</p>
                            <p style={styles.info}>Pago derivado de comisiones por ventas hacia médicos.</p>
                        </div>
                        <div className="col-md-6 form-group custom-file-upload">
                            <label className="font-weight-bold">Archivo PDF</label> <br></br>
                            <label className={facturaData.pdf != undefined ? 'custom-file-uploaded' : 'custom-file-upload1'} htmlFor="pdf"> {facturaData.pdf != undefined ? 'Cambiar' : 'Elegir'} PDF</label>
                            <input className="col-md-12" name="pdf" id="pdf" type="file" ref={pdf} onChange={onChangePdf} accept="application/pdf" />
                        </div>
                        <div className="col-md-6 form-group custom-file-upload">
                            <label className="font-weight-bold">Archivo XML</label> <br></br>
                            <label className={facturaData.xml != undefined ? 'custom-file-uploaded' : 'custom-file-upload1'} htmlFor="xml"> {facturaData.xml != undefined ? 'Cambiar' : 'Elegir'} XML</label>
                            <input className="col-md-12" name="xml" id="xml" type="file" ref={xml} onChange={onChangeXml} accept="text/xml" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    { botonesSolicitarFactura(cobroData.facturado) }
                </Modal.Footer>
            </Modal>

            <div className="row px-5 pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Indicadores generales</h5>
                            <div className="row">
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cobro.detalles.total ? cobro.detalles.total : 0)
                                    }</p>
                                    <p style={styles.infoText}>total</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cobro.detalles.total_factura ? cobro.detalles.total_factura : 0)
                                    }</p>
                                    <p style={styles.infoText}>total factura</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{facturaReminder(cobroData.facturado)}</p>
                                    <p style={styles.infoText}>factura</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{pagoReminder(cobro.detalles.pagado)}</p>
                                    <p style={styles.infoText}>pago</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Ventas involucradas</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={styles.sectionContainer}>
                                        <DataTable
                                            columns={columnsVentas}
                                            data={cobro.detalles.ventas}
                                            defaultSortField="title"
                                            noHeader={true}
                                            pagination
                                            paginationComponentOptions={paginationOptions}
                                            noDataComponent={noDataConst}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Historial de acciones</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={styles.sectionContainer}>
                                        <DataTable
                                            columns={columnsAcciones}
                                            data={cobro.detalles.historial_status}
                                            defaultSortField="title"
                                            noHeader={true}
                                            pagination
                                            paginationComponentOptions={paginationOptions}
                                            noDataComponent={noDataConst}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default CobroDetalles;
