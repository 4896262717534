import React, { createRef, useEffect, useState, Fragment } from 'react'
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Badge, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { setAlert } from '../../actions/alert';
import { detallesVenta, actualizarStatus } from '../../actions/ventas';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    btnStatus: {
        cursor: 'pointer',
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    progressbar: {
        paddingLeft: '0px'
    }
}

const VentaDetalles = () => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>No hay acciones aún</b></p>);

    const { id } = useParams();

    const dispatch = useDispatch();

    const venta = useSelector(state => state.ventas);

    const [pagoModal, setPagoModal] = useState(false);
    
    const [facturaModal, setFacturaModal] = useState(false);
    
    const [statusModal, setStatusModal] = useState({
        show: false,
        texto: ''
    });

    const [ventaData, setVentaData] = useState({
        id: '',
        status: 0
    });

    const [facturaData, setFacturaData] = useState({
        pdf: undefined,
        xml: undefined
    });

    let pdf = createRef();
    
    let xml = createRef();

    const onChangePdf = () => {
        setFacturaData({
            ...facturaData, pdf: pdf.current.files[0]
        })
    }

    const onChangeXml = () => {
        setFacturaData({
            ...facturaData, xml: xml.current.files[0]
        })
    }

    const handlePago = () => {
        setPagoModal(true);
    }

    const handleFactura = () => {
        setFacturaModal(true);
    }

    const handleStatus = (status) => {
        setStatusModal({...statusModal, show: true});

        switch(status){
            case 0:
                setStatusModal({...statusModal, show: true, texto: 'Para iniciar la venta, asegurate de que este asignada a una orden. Si no es asi, dirigite a la seccion "Ordenes"'});
                break;

            case 1:
                setStatusModal({...statusModal, show: true, texto: 'Si has completado el proceso de tu venta, es momento de finalizarla.'});
                break;

            case 2:
                setStatusModal({...statusModal, show: true, texto: 'La venta ya ha sido finalizada y entregada al médico.'});
                break;

            case 3:
                setStatusModal({...statusModal, show: true, texto: 'Esta venta fue cancelada.'});
                break;

            default:
                setStatusModal({...statusModal, show: true, texto: ''});
                break;
        }
    }

    const changeOrden = (id, status) => {
        if(venta.detalles.id_orden != null){
            dispatch(actualizarStatus({id, status}));
            //setVentaData({ ...ventaData, status: status });
        }else{
            dispatch(setAlert('La venta no tiene una órden asingada', 'danger'));
        }
        setStatusModal({...statusModal, show: false});
    }

    useEffect(() => {
        dispatch(detallesVenta(id));
    }, [id]);

    useEffect(() => {
        setVentaData({
            ...ventaData,
            id: venta.detalles?._id,
            status: venta.detalles?.status
        });
    }, [venta]);

    const columnsAcciones = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Acción',
            selector: 'descripcion',
            sortable: true,
            cell: item => item.descripcion ? item.descripcion : '-',
        }
    ]

    const ordenLinker = (id_orden, folio_orden) => {
        if(id_orden != null){
            return <Link className="btn btn-link text-primary" to={`/ordenes/detalles/${id_orden}`}>{folio_orden}</Link>
        }else{
            return <Badge variant="danger">Sin órden asignada</Badge>;
        }
    }

    const statusReminder = (status) => {
        switch(status){
            case 0:
                return <Badge variant="primary" style={styles.btnStatus} onClick={() => handleStatus(0)}>Pendiente</Badge>;
                break;

            case 1:
                return <Badge variant="warning" style={styles.btnStatus} onClick={() => handleStatus(1)}>En proceso</Badge>;
                break;

            case 2:
                return <Badge variant="success" style={styles.btnStatus} onClick={() => handleStatus(2)}>Entregada</Badge>;
                break;

            case 3:
                return <Badge variant="danger" style={styles.btnStatus} onClick={() => handleStatus(3)}>Cancelada</Badge>;
                break;
        }
    }

    const botonesSolicitarStatus = (status) => {
        if(status == 0){
            return <Button variant="primary" onClick={() => changeOrden(venta.detalles._id, 1)}>
                            Iniciar proceso
                        </Button>;
        }

        if(status == 1){
            return <Button variant="success" onClick={() => changeOrden(venta.detalles._id, 2)}>
                            Entregar órden
                        </Button>;
        }
    }

    return (
        venta.detalles ? <Fragment>
            {/* Modal de status */}
            <Modal show={statusModal.show} onHide={() => {
                setStatusModal({...statusModal, show: false});
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Status de venta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ventaData.status != 3 && (
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <ul id="progressbar" style={styles.progressbar}>
                                    <li className={ventaData.status >= 0 ? 'active venta' : 'venta'} id="iniciada-li"><strong>Pendiente</strong></li>
                                    <li className={ventaData.status >= 1 ? 'active venta' : 'venta'} id="preautorizada-li"><strong>En proceso</strong></li>
                                    <li className={ventaData.status >= 2 ? 'active venta' : 'venta'} id="complementada-li"><strong>Finalizada</strong></li>
                                </ul>
                            </div>
                        </div>
                    )}

                    <p style={styles.info}>{statusModal.texto}</p>
                </Modal.Body>
                <Modal.Footer>
                    { botonesSolicitarStatus(ventaData.status) }
                </Modal.Footer>
            </Modal>

            <div className="row px-5 pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Indicadores generales</h5>
                            <div className="row">
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{venta.detalles.cantidad ? venta.detalles.cantidad : 0}</p>
                                    <p style={styles.infoText}>cantidad</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(venta.detalles.total ? venta.detalles.total : 0)
                                    }</p>
                                    <p style={styles.infoText}>costo</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{
                                        new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(venta.detalles.comision ? venta.detalles.comision : 0)
                                    }</p>
                                    <p style={styles.infoText}>comisión</p>
                                </div>
                                {
                                    (venta.detalles.tipo == 1) ?
                                        <Fragment>
                                            <div className="col-md">
                                                <p style={styles.infoNumero}>{ordenLinker(venta.detalles.id_orden, venta.detalles.folio_orden)}</p>
                                                <p style={styles.infoText}>órden de compra</p>
                                            </div>
                                            <div className="col-md">
                                                <p style={styles.infoNumero}>{statusReminder(ventaData.status)}</p>
                                                <p style={styles.infoText}>status</p>
                                            </div>
                                        </Fragment>
                                    :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Información del médico</h5>
                            <div className="row">
                                <div className="col-md-3 px-5">
                                    <div className="d-flex justify-content-center">
                                        { venta.detalles.url_perfil ?
                                            <img
                                                height="150"
                                                className="my-3 text-center"
                                                src={venta.detalles.url_perfil}
                                            ></img>
                                        :   [
                                                (venta.detalles.sexo == 'm' ?
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_dr.png"
                                                        ></img>
                                                    :
                                                        <img
                                                            height="150"
                                                            className="my-3 text-center"
                                                            src="/assets/images/avatar_dra.png"
                                                        ></img>
                                                )
                                            ]
                                        }
                                    </div>
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 text-center">
                                            <h4 className="">{venta.detalles.nombre} {venta.detalles.apellido}</h4>
                                            <h3 className="text-muted"><Badge variant="primary">Médico</Badge></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 row">
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Nombre</p>
                                        <p style={styles.info}>{venta.detalles.nombre_medico}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Teléfono</p>
                                        <p style={styles.info}>{venta.detalles.telefono_medico}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p style={styles.subtitulo}>Correo</p>
                                        <p style={styles.info}>{venta.detalles.correo_medico}</p>
                                    </div>
                                    <div className="col-md-12">
                                        <p style={styles.subtitulo}>Dirección</p>
                                        <p style={styles.info}>{venta.detalles.direccion_medico?.direccion ? venta.detalles.direccion_medico?.direccion : 'Sin dirección'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Historial de acciones</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={styles.sectionContainer}>
                                        <DataTable
                                            columns={columnsAcciones}
                                            data={venta.detalles.historial_status}
                                            defaultSortField="title"
                                            noHeader={true}
                                            pagination
                                            paginationComponentOptions={paginationOptions}
                                            noDataComponent={noDataConst}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default VentaDetalles;
