
// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const EDIT_PERFIL = 'EDIT_PERFIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const RECUPERAR = 'RECUPERAR';
export const RESTABLECER = 'RESTABLECER';

// DASHBOARD
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';

// LOADER
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

// VENTAS
export const LOAD_VENTAS = 'LOAD_VENTAS';
export const LOAD_VENTAS_INDIRECTAS = 'LOAD_VENTAS_INDIRECTAS';
export const DETAIL_VENTA = 'DETAIL_VENTA';
export const LOAD_STATUS = 'LOAD_STATUS';

// ORDENES
export const LOAD_ORDENES = 'LOAD_ORDENES';
export const LOAD_ELEGIBLES = 'LOAD_ELEGIBLES';
export const LOAD_EDITABLES = 'LOAD_EDITABLES';
export const ADD_ORDEN = 'ADD_ORDEN';
export const EDIT_ORDEN = 'EDIT_ORDEN';
export const DETAIL_ORDEN = 'DETAIL_ORDEN';
export const DELETE_ORDEN = 'DELETE_ORDEN';
export const LOAD_ACCION = 'LOAD_ACCION';

// COBROS
export const LOAD_COBROS = 'LOAD_COBROS';
export const DETAIL_COBRO = 'DETAIL_COBRO';
export const LOAD_FACTURA = 'LOAD_FACTURA';

// MEDICOS
export const LOAD_MEDICOS = 'LOAD_MEDICOS';
export const LOAD_MIS_MEDICOS = 'LOAD_MIS_MEDICOS';
export const DETAIL_MEDICO = 'DETAIL_MEDICO';
export const DELETE_MEDICO = 'DELETE_MEDICO';
export const LOAD_SOLICITUDES = 'LOAD_SOLICITUDES';
export const ACCEPT_MEDICO = 'ACCEPT_MEDICO';