import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Badge, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { setAlert } from '../../actions/alert';
import { detallesOrden, actualizarStatus } from '../../actions/ordenes';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    btnStatus: {
        cursor: 'pointer',
    },
    bordeDer: {
        borderRight: '1px solid #0000001a'
    },
    botonArchivos: {
        background: 'transparent',
        boxShadow: '0px 0px 0px transparent',
        border: '0px solid transparent',
        textShadow: '0px 0px 0px transparent'
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    progressbar: {
        paddingLeft: '0px'
    }
}

const OrdenDetalles = () => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const noDataConst = (<p><b>No hay resultados a mostrar</b></p>);

    const { id } = useParams();

    const dispatch = useDispatch();

    const orden = useSelector(state => state.ordenes);

    const [ordenData, setOrdenData] = useState({
        id: '',
        status: 0
    });

    const [statusModal, setStatusModal] = useState({
        show: false,
        texto: ''
    });

    const handleStatus = (status) => {
        setStatusModal({...statusModal, show: true});

        switch(status){
            case 0:
                setStatusModal({...statusModal, show: true, texto: 'Actualmente tu orden se encuentra "Pendiente". Debes esperar a que el personal de Biogenica atienda tu orden.'});
                break;

            case 1:
                setStatusModal({...statusModal, show: true, texto: 'Tu orden se encuentra "En Proceso". El personal de Biogenica esta trabajando en ella. Pronto se te contactará.'});
                break;

            case 2:
                setStatusModal({...statusModal, show: true, texto: 'La orden ya ha sido finalizada. Si no has acudido a recoger tu pedido, deberas de hacerlo ahora.'});
                break;

            case 3:
                setStatusModal({...statusModal, show: true, texto: 'Esta orden fue cancelada.'});
                break;

            default:
                setStatusModal({...statusModal, show: true, texto: ''});
                break;
        }
    }

    const changeOrden = (id, status) => {
        dispatch(actualizarStatus({id, status}));
        setOrdenData({ ...ordenData, status: status });
    }

    useEffect(() => {
        dispatch(detallesOrden(id));
    }, [id]);

    useEffect(() => {
        setOrdenData({
            ...ordenData,
            id: orden.detalles?._id,
            status: orden.detalles?.status
        });
    }, [orden]);

    const statusVentas = (status) => {
        switch(status){
            case 0:
                return <Badge variant="primary">Pendiente</Badge>;
                break;

            case 1:
                return <Badge variant="warning">En proceso</Badge>;
                break;

            case 2:
                return <Badge variant="success">Entregada</Badge>;
                break;

            case 3:
                return <Badge variant="danger">Cancelada</Badge>;
                break;
        }
    }

    const columnsVentas = [
        {
            name: 'Cantidad',
            selector: 'cantidad',
            sortable: true,
            cell: item => item.cantidad ? item.cantidad : '-',
        },
        {
            name: 'Médico',
            selector: 'nombre_medico',
            sortable: true,
            cell: item => item.nombre_medico ? item.nombre_medico : '-',
        },
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            cell: item => statusVentas(item.status),
        }
    ]

    const columnsAcciones = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Acción',
            selector: 'descripcion',
            sortable: true,
            cell: item => item.descripcion ? item.descripcion : '-',
        }
    ]

    const statusReminder = (status) => {
        switch(status){
            case 0:
                return <Badge variant="primary" style={styles.btnStatus} onClick={() => handleStatus(0)}>Pendiente</Badge>;
                break;

            case 1:
                return <Badge variant="warning" style={styles.btnStatus} onClick={() => handleStatus(1)}>En proceso</Badge>;
                break;

            case 2:
                return <Badge variant="success" style={styles.btnStatus} onClick={() => handleStatus(2)}>Finalizada</Badge>;
                break;

            case 3:
                return <Badge variant="danger" style={styles.btnStatus} onClick={() => handleStatus(3)}>Cancelada</Badge>;
                break;
        }
    }

    return (
        orden.detalles ? <Fragment>
            {/* Modal de status */}
            <Modal show={statusModal.show} onHide={() => {
                setStatusModal({...statusModal, show: false});
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Status de venta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ordenData.status != 3 && (
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <ul id="progressbar" style={styles.progressbar}>
                                    <li className={ordenData.status >= 0 ? 'active venta' : 'venta'} id="iniciada-li"><strong>Pendiente</strong></li>
                                    <li className={ordenData.status >= 1 ? 'active venta' : 'venta'} id="preautorizada-li"><strong>En proceso</strong></li>
                                    <li className={ordenData.status >= 2 ? 'active venta' : 'venta'} id="complementada-li"><strong>Finalizada</strong></li>
                                </ul>
                            </div>
                        </div>
                    )}

                    <p style={styles.info}>{statusModal.texto}</p>
                </Modal.Body>
            </Modal>

            <div className="row px-5 pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Indicadores generales</h5>
                            <div className="row">
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{orden.detalles.cantidad ? orden.detalles.cantidad : 0}</p>
                                    <p style={styles.infoText}>cantidad</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{orden.detalles.ventas ? orden.detalles.ventas.length : 0}</p>
                                    <p style={styles.infoText}>número de ventas</p>
                                </div>
                                <div className="col-md">
                                    <p style={styles.infoNumero}>{statusReminder(ordenData.status)}</p>
                                    <p style={styles.infoText}>status</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Ventas involucradas</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={styles.sectionContainer}>
                                        <DataTable
                                            columns={columnsVentas}
                                            data={orden.detalles.ventas}
                                            defaultSortField="title"
                                            noHeader={true}
                                            pagination
                                            paginationComponentOptions={paginationOptions}
                                            noDataComponent={noDataConst}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 style={styles.titulo}>Historial de acciones</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={styles.sectionContainer}>
                                        <DataTable
                                            columns={columnsAcciones}
                                            data={orden.detalles.historial_status}
                                            defaultSortField="title"
                                            noHeader={true}
                                            pagination
                                            paginationComponentOptions={paginationOptions}
                                            noDataComponent={noDataConst}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> : <div></div>
    )
}




export default OrdenDetalles;
